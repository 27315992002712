<template>
  <el-row class="address-picker">
    <el-col style="line-height: 31px" :span="spanValue">
      <span>{{ addressList.join('-') }} </span>
      <span> {{ detail || ' ' }}</span>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'AddressPickerDetail',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // 展示样式 支持 inline 单行显示 block 分行显示
    layout: {
      type: String,
      default: 'block'
    },
    value: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      spanValue: this.layout === 'inline' ? 12 : 24,
      options: [],
      select: [],
      detail: '',
      dataList: {},
      addressList: []
    }
  },
  watch: {
    value (newValue, oldValue) {
      this.getData(newValue)
    }
  },
  created () {
  },
  methods: {
    getData (newValue) {
      this.select = [newValue[0], newValue[1], newValue[2]]
      this.detail = newValue[3]
      this.utils.getProvinceCityInfo().then(result => {
        this.dataList = result
        this.lazyLoad()
      }).catch(e => {
      })
    },
    lazyLoad () {
      this.addressList = []
      this.dataList.province.forEach(item => {
        if (item.key === this.select[0]) {
          this.addressList.push(item.label)
        }
      })
      this.dataList.city.forEach(item => {
        if (item.key === this.select[1]) {
          this.addressList.push(item.label)
        }
      })
      this.dataList.area.forEach(item => {
        if (item.key === this.select[2]) {
          this.addressList.push(item.label)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.address-picker {
  .inline {
    margin-left: 15px;
  }
  .block {
    margin-top: 15px;
  }
}
</style>
