<template>
  <el-row class="address-picker">
    <el-col :span="spanValue">
      <el-cascader
        v-if="isShowAddressBool"
        v-model="select"
        style="width: 100%"
        :props="{ expandTrigger: 'hover', lazy: true, lazyLoad: lazyLoad, value: 'key' }"
        @change="changeValue"
      />
    </el-col>
    <el-col :span="spanValue">
      <div :class="layout">
        <el-input v-model="detail" @change="changeValue" />
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'AddressPicker',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // 展示样式 支持 inline 单行显示 block 分行显示
    layout: {
      type: String,
      default: 'block'
    },
    value: {
      type: Array,
      default () {
        return []
      }
    },
    isShowAddressInfo: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      spanValue: this.layout === 'inline' ? 12 : 24,
      options: [],
      select: [],
      detail: '',
      dataList: {},
      isShowAddressBool: true
    }
  },
  watch: {
    value (newValue, oldValue) {
      this.select = [newValue[0], newValue[1], newValue[2]]
      this.detail = newValue[3]
      if (!this.isShowAddressInfo) {
        this.isShowAddressBool = false
      }
      setTimeout(() => {
        this.isShowAddressBool = true
      }, 1)
    }
  },
  created () {
  },
  methods: {
    changeValue () {
      this.$emit('change', [this.select[0], this.select[1], this.select[2], this.detail])
    },
    getData () {
      return new Promise((resolve, reject) => {
        this.utils.getProvinceCityInfo().then(result => {

          this.dataList = result
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
    lazyLoad (node, resolve) {
      if (node.level === 0) {
        this.getData().then(() => {
          resolve(this.dataList.province)
        })
      } else if (node.level === 1) {
        resolve(this._.filter(this.dataList.city, { parentCode: node.value }))
      } else if (node.level === 2) {
        const areaData = this._.filter(this.dataList.area, { parentCode: node.value })
        areaData.forEach(item => {
          item.leaf = true
        })
        resolve(areaData)
      } else {
        resolve([])
      }
    }
  }
}
</script>

<style lang="less" scoped>
.address-picker {
  .inline {
    margin-left: 15px;
  }
  .block {
    margin-top: 15px;
  }
}
</style>
