var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "address-picker" },
    [
      _c(
        "el-col",
        {
          staticStyle: { "line-height": "31px" },
          attrs: { span: _vm.spanValue }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.addressList.join("-")) + " ")]),
          _c("span", [_vm._v(" " + _vm._s(_vm.detail || " "))])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }